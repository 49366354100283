import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { renderParagraphs } from "@helper/paragraph";
import { NextRouter, useRouter } from "next/router";
import React from "react";
import { NodeProps } from "@model/node.model";
import Breadcrumbs from "@components/Common/Breadcrumbs/Breadcrumbs";
import useJournalSlugTopicData from "@hooks/NextJournal/useJournalSlugTopicData";
import useJournalSlugArticles from "@hooks/NextJournal/useJournalSlugArticles";
import ContentHeader from "@components/Modules/NextJournal/ContentHeader/ContentHeader";
import CategoryCardsSection from "@components/Modules/NextJournal/CategoryCardsSection/CategoryCardsSection";
import ArticleCardGrid from "@components/Modules/NextJournal/ArticleCardGrid/ArticleCardGrid";
import { isCardComplete } from "@components/Modules/NextJournal/constants";
import Layout from "@components/Layout/variant/LayoutDefault";
import Loader from "@components/Common/Loader/Loader";

const TaxonomyTermCategoriaArticolo: React.FC<NodeProps> = ({ node, articlesPerSubCategory, menus, langData }) => {
  const router: NextRouter = useRouter();
  const { topic: topicId, page = '1' } = router?.query;
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));
  const isLongform = node?.name === "Longform";
  const isSubCategory: boolean = node?.name === 'NEXT Focus' || node?.name === 'NEXT Stories';

  const paragraphs = node?.field_paragraphs ? renderParagraphs(node) : null;

  const { pageTitle } = useJournalSlugTopicData({ topicId, node, isArticlePage: false, categoryTitle: node?.name });

  const { items, isLoading } = useJournalSlugArticles({ isArticlePage: false, topicId, categoryId: node?.id, infiniteScroll: true, isLongform });

  return (
    <Layout menus={menus} node={node} langData={langData}>
      <Breadcrumbs node={node} />

      {paragraphs}

      <Container maxWidth="xl" sx={{ pt: { xs: 40, lg: 0 }, pb: { sm: 300, lg: 120 } }} >

        {isSubCategory ? (
          articlesPerSubCategory?.map((subCategory, i) => (<CategoryCardsSection key={subCategory?.category?.name} {...subCategory} i={i} />))
        ) : (
          <>
            <Box sx={{ pt: { xs: 0, lg: '10rem' } }}>
              <ContentHeader isMobile={isMobile} title={pageTitle} />
            </Box>
            {items && <ArticleCardGrid articles={items} cardVariant={isCardComplete(node?.name) ? 'complete' : 'default'} />}
            {isLoading ? <Loader active /> : <></>}
          </>
        )}

      </Container>
    </Layout>
  );
}

export default TaxonomyTermCategoriaArticolo