import { NodeProps } from "@model/node.model";
import NodeArticle from "./node--article";
import NodeBasicPage from "./node--basic-page";
import NodeImplant from "./node--implant";
import TaxonomyTermCategoriaArticolo from "./taxonomy-term--categoria_articolo";
import TaxonomyTermImplantType from "./taxonomy-term--implant_type";
import NodeLongform from "./node--longform";

export const Node: React.FC<NodeProps> = ({node, ...otherProps}) => {
  
  const nodeType = {
    "node--page": NodeBasicPage,
    "node--article": NodeArticle,
    "node--implants": NodeImplant,
    "taxonomy_term--categoria_articolo": TaxonomyTermCategoriaArticolo,
    "taxonomy_term--implat_types": TaxonomyTermImplantType,
    "node--longform": NodeLongform,
  }

  const Component = nodeType[node.type] || null;
  return <Component node={node} {...otherProps} />;
}

export default Node