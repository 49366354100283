import Container from "@mui/material/Container";
import { renderParagraphs } from "@helper/paragraph";
import { NodeProps } from "@model/node.model";
import Breadcrumbs from "@components/Common/Breadcrumbs/Breadcrumbs";
import RelatedArticlesSection from "@components/Modules/NextJournal/RelatedArticlesSection/RelatedArticlesSection";
import TopicChipsSection from "@components/Modules/NextJournal/TopicChipsSection/TopicChipsSection";
import Layout from "@components/Layout/variant/LayoutDefault";

const NodeArticle: React.FC<NodeProps> = ({
  node,
  topics,
  menus,
  langData,
}) => {
  const paragraphs = node?.field_paragraph ? renderParagraphs(node) : null;

  return (
    <Layout menus={menus} node={node} langData={langData}>
      <Breadcrumbs node={node} />

      {paragraphs}

      {topics?.length ? (
        <Container maxWidth={false} sx={{ pb: "4rem", pt: "6rem" }}>
          <TopicChipsSection articleTopics={topics} />
        </Container>
      ) : null}

      {node?.field_related?.length ? (
        <RelatedArticlesSection relatedArticles={node?.field_related ?? []} />
      ) : null}
    </Layout>
  );
};

export default NodeArticle;
