import Layout from "@components/Layout";
import { renderParagraphs } from "@helper/paragraph";
import { NodeProps } from "@model/node.model";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import StickyBar from "@components/Common/StickyBar";
import LongFormFooter from "@components/Common/LongFormFooter/LongFormFooter";
import LongFormNav from "@components/Common/LongFormNav/LongFormNav";
import React from "react";
import LongFormPreFooter from "@components/Common/LongFormPreFooter/LongFormPreFooter";

const NodeLongform: React.FC<NodeProps> = (props) => {
  const { node, langData, menus } = props;
  const fieldParagraph = node?.field_paragraph ? renderParagraphs(node) : null;

  const [links, setLinks] = useState([]);

  useEffect(() => {
    const fetchLinks = async () => {
      const response = await fetch(`/api/longform/${node.field_group}`);
      const data = await response.json();
      setLinks(data);
    };
    fetchLinks();
  }, [node]);

  return (
    <Box bgcolor={"#F6F7F8"} className="long-form">
      <Layout
        variant={"NoLayout"}
        node={node}
        langData={langData}
        menus={menus}
      >
        {fieldParagraph}
      </Layout>

      {links.length ? <LongFormPreFooter node={node} chapters={links} /> : null}
      <LongFormFooter menus={menus} />
      <StickyBar type={node?.field_stickybar} />
      {links.length ? <LongFormNav links={links} node={node} /> : null}
    </Box>
  );
};

export default NodeLongform;
