import NorthIcon from "@mui/icons-material/North";
import {
  Box,
  Divider,
  Fab,
  Icon,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import theme from "@styles/mui/themeLongForm";
import { DrupalNode } from "next-drupal";
import Link from "next/link";
import React, { useState } from "react";
import HideOnScroll from "../HideOnScroll/HideOnScroll";
import styles from "./LongFormNav.module.scss";

let gsap;
let ScrollToPlugin;

if (typeof window !== "undefined") {
  (async () => {
    const gsapModule = await import("gsap");
    const scrollToPluginModule = await import("gsap/ScrollToPlugin");
    gsap = gsapModule.gsap;
    ScrollToPlugin = scrollToPluginModule.ScrollToPlugin;
    gsap.registerPlugin(ScrollToPlugin);
  })();
}

interface LongFormNavProps {
  links: DrupalNode[];
  node: DrupalNode;
}

const LongFormNav: React.FC<LongFormNavProps> = ({ links, node }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));
  const [open, setOpen] = useState(false);

  const handleScrollToTop = () => {
    if (gsap && ScrollToPlugin) {
      gsap.to(window, {
        scrollTo: { y: 0, autoKill: true },
        duration: 1,
        ease: "power2.inOut",
      });
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  }

  return (
    <>
      <HideOnScroll>
        {isMobile ? (
          <>
            <Fab className={`${styles.floatingButton} ${open ? styles.open : null}`} onClick={toggleDrawer}>
              <Icon className={styles.icon}>
                {open ? "close" : "menu"}
              </Icon>
            </Fab>

            <Modal open={open} onClose={toggleDrawer}>
              <Fade in={open} timeout={500}>
                <Box className={styles.modalContent}>

                  <Stack
                    alignItems="center"
                    className={styles.navContainer}
                    divider={
                      <Divider
                        flexItem
                        sx={{ borderColor: theme.palette.secondary.dark }}
                      />
                    }
                  >
                    {links?.map((link) => (
                      <Box
                        key={link?.id}
                        component={Link}
                        href={link?.path?.alias}
                        target="_self"
                        onClick={toggleDrawer}
                        className={`${styles.button} ${styles.chapter} ${node.field_chapter === link?.field_chapter ? styles.active : ""}`}
                      >
                        <Typography variant="h2">{link?.field_chapter}</Typography>
                      </Box>
                    ))}
                  </Stack>
                  <Box className={styles.modalFooter} />
                </Box>

              </Fade>
            </Modal>
          </>
        ) : (
          <Stack
            direction="row"
            className={styles.navLongFormContainer}
            alignItems="center"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: theme.palette.secondary.dark }}
              />
            }
          >

            <Box
              className={`${styles.button} ${styles.up}`}
              sx={{ width: 100 }}
              onClick={handleScrollToTop}
            >
              <NorthIcon sx={{ color: theme.palette.secondary.main }} />
            </Box>

            {links?.map((link, index) => (
              <Box
                key={index}
                component={Link}
                href={link?.path?.alias}
                target="_self"
                className={`${styles.button} ${styles.chapter} ${node.field_chapter === link?.field_chapter ? styles.active : ""}`}
              >
                <Typography variant="h2">{link?.field_chapter}</Typography>
              </Box>
            ))}
          </Stack>
        )}
      </HideOnScroll>
    </>
  )
};

export default LongFormNav;
