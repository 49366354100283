
import LongFormChapterArrow from "@components/UI/Icons/LongFormChapterArrow";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DrupalNode } from "next-drupal";
import Link from "next/link";
import styles from "./LongFormPreFooter.module.scss";

interface LongFormFooterProps {
  node: DrupalNode;
  chapters: DrupalNode[];
}

const LongFormPreFooter: React.FC<LongFormFooterProps> = ({ node, chapters }) => {
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));

  const currentChapter = node.field_chapter;
  const isLastChapter = chapters[chapters.length - 1]?.field_chapter === currentChapter;

  const nextChapter = chapters.find((chapter) => chapter.field_chapter === currentChapter + 1);
  const firstChapter = chapters.find((chapter) => chapter.field_chapter === 1);

  const chapter: DrupalNode = isLastChapter ? firstChapter : nextChapter;

  return (
    <Box className={styles.preFooter}>
      <Stack direction={"row"} alignItems={"center"} gap={{ xs: 16, lg: 32 }} component={chapter ? Link : 'div'} href={chapter?.path?.alias}>

        <Typography variant="h2" component="div">{'Capitolo'}</Typography>

        <Typography variant="h2" component="div" className={styles.chapter}>
          {chapter?.field_chapter}
        </Typography>

        <Box className={styles.icon}>
          <LongFormChapterArrow width={isMobile ? 52 : 120} height={isMobile ? 28 : 64} />
        </Box>

      </Stack>
    </Box>
  );
}

export default LongFormPreFooter;