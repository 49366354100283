import { Fade } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import { ReactNode } from 'react';

interface HideOnScrollProps {
  children: ReactNode;
}

const HideOnScroll = (props: HideOnScrollProps) => {

  const { children } = props;
  const trigger = useScrollTrigger({
    threshold: 100,
    disableHysteresis: false,
  });
  return (
    <Fade in={!trigger} timeout={400}>
      <div>{children}</div>
    </Fade>
  );
}

export default HideOnScroll;