import Breadcrumbs from "@components/Common/Breadcrumbs/Breadcrumbs";
import { renderParagraphs } from "@helper/paragraph";
import AuthSection from "@components/Authentication/AuthSection";
import { NodeProps } from "@model/node.model";
import Layout from "@components/Layout/variant/LayoutDefault";

const NodeImplant: React.FC<NodeProps> = ({
  node,
  isProtectedPath,
  menus,
  langData,
}) => {
  const fieldParagraph = node?.field_paragraph ? renderParagraphs(node) : null;

  return (
    <Layout menus={menus} node={node} langData={langData}>
      <Breadcrumbs node={node} />
      {isProtectedPath && <AuthSection />}
      {fieldParagraph}
    </Layout>
  );
};

export default NodeImplant;
