const LongFormChapterArrow = ({
  fill = "#F6F7F8",
  width = 120,
  height = 64,
  ...otherProps
}: {
  fill?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <g clipPath="url(#clip0_7970_2920)">
        <path d="M116.667 32C116.667 48.5685 103.235 62 86.6665 62C70.098 62 56.6665 48.5685 56.6665 32C56.6665 15.4315 70.098 2 86.6665 2C103.235 2 116.667 15.4315 116.667 32Z" stroke={fill} strokeWidth="4" />
      </g>
      <g clipPath="url(#clip1_7970_2920)">
        <circle cx="22.6665" cy="48" r="14" stroke={fill} strokeWidth="4" />
      </g>
      <line x1="87.333" y1="2" x2="7.33301" y2="2" stroke={fill} strokeWidth="4" />
      <path d="M88.0807 35.4142C88.8618 34.6332 88.8618 33.3668 88.0807 32.5858L75.3528 19.8579C74.5717 19.0768 73.3054 19.0768 72.5244 19.8579C71.7433 20.6389 71.7433 21.9052 72.5244 22.6863L83.8381 34L72.5244 45.3137C71.7433 46.0948 71.7433 47.3611 72.5244 48.1421C73.3054 48.9232 74.5717 48.9232 75.3528 48.1421L88.0807 35.4142ZM86.6665 32L22.6665 32V36L86.6665 36V32Z" fill={fill} />
      <line x1="86.6665" y1="62" x2="22.6665" y2="62" stroke={fill} strokeWidth="4" />
      <defs>
        <clipPath id="clip0_7970_2920">
          <rect width="32" height="64" fill="white" transform="translate(86.6665)" />
        </clipPath>
        <clipPath id="clip1_7970_2920">
          <rect width="16" height="32" fill="white" transform="translate(6.6665 32)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LongFormChapterArrow;
