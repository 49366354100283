import Link from "next/link";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import LinkedIn from "@mui/icons-material/LinkedIn";

import Row from "@components/Modules/Utils/Row/Row";
import Logo from "@components/UI/Logo/Logo";
import { renderCopyrightMenu } from "@helper/menu";
import { NextRouter, useRouter } from "next/router";
import styles from "./LongFormFooter.module.scss";

import { useTranslation } from "next-i18next";
import { useMediaQuery, useTheme } from "@mui/material";

export default function LongFormFooter({
  menus: {
    copyrightMenu,
  },
}) {
  const { t } = useTranslation();
  const router: NextRouter = useRouter();
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));

  const socials = [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/edison-next/",
      icon: <LinkedIn />,
    },
  ];

  return (
    <footer className={styles.footer}>
      <Container maxWidth={false} className={styles.footerContent}>
        <Row alignItems={"center"}>
          <Grid item xs={12} md={9}>
            <Link href="/" aria-label="Logo Edison NEXT">
              <Logo color="#ffffff" />
            </Link>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography
              variant="bodyS"
              component="p"
              fontWeight={300}
              mt={{ xs: 32, md: 0 }}
              textAlign={isMobile ? "left" : "right"}
            >
              {t("footer.social")}
            </Typography>

            <Grid
              container
              columnSpacing={22}
              mt={16}
              justifyContent={isMobile ? "flex-start" : "flex-end"}
            >
              {socials &&
                socials.map((el, i) => {
                  return (
                    <Grid item key={i}>
                      <Link href={el.url} passHref aria-label={el.name}>
                        {el.icon}
                      </Link>
                    </Grid>
                  );
                })}
              <Grid item>
                <Link
                  href={"https://www.edison.it/"}
                  passHref
                  aria-label="Edison"
                  target="_blank"
                >
                  <Typography variant="description" className={styles.linkDesc}>
                    edison.it
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href={"https://www.edisonnext.es/"}
                  passHref
                  aria-label="Edison"
                  target="_blank"
                >
                  <Typography variant="description" className={styles.linkDesc}>
                    edisonnext.es
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Row>
      </Container>

      <Container maxWidth={false} className={styles.copyright}>
        <Row>
          <Grid item md={6} lg={7}>
            <Typography variant="bodyS" component="p" fontWeight={300} mb={16}>
              Copyright © 2024 Edison Next S.p.A. - Via Acqui 86, 10098 Rivoli
              (TO)
            </Typography>
            <Typography variant="bodyS" component="p" fontWeight={300}>
              Società a socio unico soggetta all’attività di direzione e
              coordinamento di Edison S.p.A.
              <br />
              Capitale Soc. euro 330.500.000 i.v. - Reg. Imprese di TORINO C.F.
              13032970157 - PEC: edisonnext@pec.edison.it
              <br />
              <Link
                href={
                  router.locale === "it"
                    ? "https://email.edisonnext.it/hubfs/Informative%20privacy/A4%20informativa_2022_12_web.pdf"
                    : "/en/legal-information-and-certifications"
                }
                passHref
                aria-label="legal"
                target="_blank"
              >
                <strong>Informazioni legali e certificazioni</strong>
              </Link>{" "}
              con i dettagli di contatto per singola legal entity
            </Typography>
          </Grid>

          <Grid item md={6} lg={5} mt={{ xs: 16, lg: 0 }} className={styles.copyrightMenu}>
            {renderCopyrightMenu(copyrightMenu)}
          </Grid>
        </Row>
      </Container>
    </footer>
  );
}
